<template>
  <div v-if="dialog">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition" max-width="50%">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              !roleLevelData._id
                ? $t("title.create_roleLevel")
                : $t("title.update_roleLevel")
            }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="loading" text @click="validate">
              {{
                !roleLevelData._id ? $t("button.create") : $t("button.update")
              }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <!-- <v-row class="mt-5">
                <v-text-field
                    v-model="roleLevelData.role_name"
                    :label="$t('input.role_name')"
                    :rules="rules.role_name"
                />
              </v-row> -->
              <v-row class="mt-5">
                <v-select
                  v-model="roleLevelData.role_name"
                  :items="optionRoles"
                  item-text="child_role_name"
                  item-value="child_role_id"
                  :label="$t('input.role_name')"
                  disabled
                  aria-readonly="true"
                />
              </v-row>
              <v-row class="mt-5">
                <v-text-field
                  v-model="roleLevelData.child_role_name"
                  :label="$t('input.child_role_name')"
                  :rules="rules.child_role_name"
                  disabled
                  aria-readonly="true"
                />
              </v-row>
              <v-row
                v-if="roleLevelData.child_role_id == '612a3fcc1a1fcead8871e822'"
              >
                <v-text-field
                  v-model="roleLevelData.company_balance_b"
                  :label="$t('input.child_role_balance_b')"
                  :rules="rules.company_balance_b"
                  type="number"
                ></v-text-field>
              </v-row>
              <v-row
                v-if="roleLevelData.child_role_id == '612a3fcc1a1fcead8871e822'"
              >
                <v-text-field
                  v-model="roleLevelData.company_balance_d"
                  :label="$t('input.child_role_balance_d')"
                  :rules="rules.company_balance_d"
                  type="number"
                ></v-text-field>
              </v-row>
              <v-row
                v-if="roleLevelData.child_role_id == '612a3fcc1a1fcead8871e822'"
              >
                <v-text-field
                  v-model="roleLevelData.company_balance_r"
                  :label="$t('input.child_role_balance_r')"
                  :rules="rules.company_balance_r"
                  type="number"
                ></v-text-field>
              </v-row>
              <v-row v-else>
                <v-text-field
                  v-model="roleLevelData.child_role_balance"
                  :label="$t('input.child_role_balance')"
                  :rules="rules.child_role_balance"
                  type="number"
                ></v-text-field>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    darkmode: {
      type: Boolean,
    },
    optionRoles: {
      type: Array,
      default: () => {
        return [
          "DEVELOPER",
          "PLAYER",
          "AGENT",
          "MASTER",
          "SENIOR",
          "COMPANY",
          "SUB-MANAGER",
          "CHANNEL",
        ];
      },
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          role_name: [(v) => !!v || "Name is required"],
          child_role_name: [(v) => !!v || "description is required"],
          child_role_balance: [(v) => !!v || "description is required"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    roleLevelData: { type: Object, default: null },
    users: {
      type: Array,
      default: null,
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      showPassword: false,
    };
  },
  watch: {
    // optionRoles:function(newVal){
    //   this.roleLevelData.role_name = newVal[0]
    // },
    // users:function(newVal, oldVal){
    // }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("submit");
      }
    },
  },
};
</script>
